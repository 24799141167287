@import 'config/variables';
@import 'config/breakpoints';
@import 'include-media/dist/include-media';
@import 'utils/component-spacing';
@import 'components/teaser/mixins';
@import 'components/button/mixin';
@import 'components/typography/mixin';
@import 'components/video-player/video-player';
@import 'vendors/include-media';
@import 'components/container/mixin';

@import '../../../../theme-creativeshop/src/components/image-teaser/image-teaser.scss';

.#{$ns}image-teaser {
    $root: &;

    &:not(#{$root}--slider) {
        #{$root}__slides {
            justify-content: center;
        }
    }

    &__wrapper {
        &--content-under {
            #{$root}__content {
                margin: -0.6rem 0 0;
                padding: 1rem;
            }
        }
    }

}

.#{$ns}container {
    $root: &;

    &--icon {

        .#{$ns}image-teaser {
            $root: &;

            &__description {
                font-size: 1.8em;
                @include media('<tablet'){
                    font-size: 2.2em;
                }
            }

        }

    }


    &--hero-teaser {

        .#{$ns}image-teaser {
                $root: &;

            @include media('>=laptop') {
                &__slogan {
                    font-weight: 400;
                    font-size: 46px;
                }

                &__description {
                    font-weight: 100;
                    font-size: 30px;
                }
            }
        }
    }

}
